.change-status-form {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 350px;
  background-color: var(--lightDarkColor);
  border-radius: 8px;

  &-header {
    border-radius: 8px 8px 0 0;
    background-color: var(--darkColor);
    padding: 10px 20px 10px 20px;
  }

  &-body {
    background-color: var(--lightDarkColor);
    padding: 10px 20px 10px 20px;
    border-radius: 0 0 8px 8px;
  }

  &-label {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
  }

  label {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 11px;
    display: block;
    margin-bottom: 8px;
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 10px 20px;

    .btn {
      width: 45%;
      span {
        display: flex;
        align-items: center;
        i {
          margin-right: 8px;
        }
      }
      &:hover {
        i {
          background-color: var(--blueColor);
        }
      }
      margin-top: 0;
    }

    .btn-cancel {
      box-shadow: unset;
      border: none;
      background-color: #B00020;
      color: #fff;

      i {
        background-color: #fff !important;
      }
    }
  }
}