.side-by-side-map {
  width: 100%;
  height: 100%;
  display: flex;

  #mapLeft,
  #mapRight {
    width: 50%;
    height: 100%;
    border: 8px solid #F9F9F9;
  }

  #mapLeft {
    border-radius: 12px 0px 0 12px;
  }
}