.select-version {
  &__state {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;
    height: 32px;
    width: 32px;
    background-color: var(--blueColor);
  }
  &__title {
    width: fit-content;
    display: inline-block;
    margin-bottom: 5px;
    color: var(--textColor);
    font-weight: 400;
    font-size: 13px;
    font-style: normal;
  }
  &__value-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__value-input {
    width: 100%;
    cursor: pointer;
    border-radius: 8px 0 0 8px;
    background: #191A25;
    display: flex;
    align-items: center;
    padding: 7px 10px;

    .select-value {
      &:first-child {
        margin-right: 12px;
      }
    }
  }
  &__state {
    float: left;
  }

  &__option {
    &-list {
      z-index: 99;
      box-shadow: 5px 7px 20px rgba(0, 0, 0, 0.25);
      border-radius: 18px;
      padding: 10px;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 170px;
      background: var(--lightDarkColor);
      border: 1px solid var(--borderColor);
      bottom: calc(100% + 5px);
      left: 50%;
    }

    &-value {
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      font-style: normal;
      width: 100%;
      border-radius: 10px;
      padding: 10px;
      color: var(--textColor);

      &:hover {
        background-color: var(--blueColor);
        color: var(--darkColor);
      }
    }
  }
}