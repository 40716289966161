@import "src/assets/styles/variavles";

.parameters {
  .parametersTitle {
    text-transform: uppercase;
    color: var(--blueColor);
    font-size: .786rem;
    font-weight: 600;
  }

  .newName {
    background-color: #21222B;
    height: 30px;
    border-radius: 15px;
    padding-left: 15px;
    margin: 5px 0 3px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    color: var(--blueColor);
    font-weight: 600;
    flex-wrap: wrap;

    input {
      background-color: transparent;
      box-shadow: none;
      border: 0;
      color: var(--blueColor);
      font-weight: 600;
      width: 57%;
      margin-left: 3px;
      &::placeholder {
        color: var(--blueColor);
        font-weight: 500;
        opacity: .4;
      }
    }
  }

  .parametersList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      display: inline-flex;
      align-items: center;
      background-color: #191A25;
      height: 30px;
      border-radius: 15px;
      width: 50%;
      padding-left: 10px;
      margin: 3px 0;
      padding-top: 2px;

      label {
        display: flex;
        align-items: center;
        color: var(--blueColor);
        font-weight: 600;
        padding-right: 5px;

        input {
          background-color: transparent;
          box-shadow: none;
          border: 0;
          color: var(--blueColor);
          font-weight: 600;
          width: 70%;
          margin-left: 3px;
          &[readonly] {
            cursor: default;
          }
        }
      }
    }
  }
}

.geolocations {

  :global{
    .scroll-wrapper .ScrollbarsCustom-Wrapper .ScrollbarsCustom-Scroller {
      padding: 10px 25px 10px 10px !important;
    }
    .scroll-wrapper .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
      width: 3px !important;
      right: -4px !important;
    }
    .scroll-wrapper .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
      width: 7px !important;
    }
  }

  .ScrollbarsCustom-Scroller {
    padding: 10px 35px 10px 10px !important;
  }
  .geoWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding: 10px 5px 10px 10px;
    //padding: 10px;
    gap: 5px;
    position: relative;
    border-radius: 15px;
    background-color: var(--darkColor);
    //max-height: 167px;
    //overflow: auto;
  }
  //.geoWrap::-webkit-scrollbar {
  //  background-color: #2A2B37;
  //  width: 3px;
  //}
  //
  //.geoWrap::-webkit-scrollbar-thumb {
  //  background-color: #00CEFF;
  //  border-radius: 15px;
  //  width: 6px;
  //  position: relative;
  //}
  //
  //.geoWrap::-webkit-scrollbar-thumb::after {
  //  content: "";
  //  position: absolute;
  //  left: -2px;
  //  top: 0;
  //  bottom: 0;
  //  width: 10px;
  //  background-color: #2A2B37;
  //  border-radius: 15px;
  //  z-index: -1;
  //}
  .geoInformation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    background-color: var(--lightDarkColor);
    border-radius: 10px;

    .rowItem {
      color: var(--blueColor);
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      display: flex;
      align-items: center;

      .span {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
      }
      .iconWrap {
        margin: 0 4px 0 11px;
      }
    }
    .rowItem:not(:last-child) {
      margin-bottom: 10px;
    }
  }

}

.parametersHelper {
  color: red;
  font-weight: 600;
  margin: 0 2px 0 auto;
}

.sectionTitle {
  text-transform: uppercase;
  color: var(--blueColor);
  font-size: .85rem;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 4px;

  span {
    cursor: pointer;
    color: var(--blueColor);
  }

  .ScrollbarsCustom-Wrapper .ScrollbarsCustom-Scroller {
    padding: 10px !important;
    // padding-right: 40px!important;
  }
}