.map {
  position: relative;
  flex: 1;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  background-color: var(--darkColor);
  transition: all .25s ease;
  opacity: 1;

  .hidden {
    opacity: 0;
  }

  .gMapWrapper {
    height: calc(100% + 25px);
    background-color: var(--darkColor);
  }
}