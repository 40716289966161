.select-value {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--textColor);

  &:last-child {
    i {
      margin-right: 5px;
    }
  }

}