.tile-info-select {
  border: 1px solid rgb(226, 229, 235);
  padding: 5px 8px;
  width: 100%;
}

.tile-info-col {
  position: relative;
}

.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: var(--blueColor) !important;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  white-space: nowrap;
  padding: 8px 13px;
  top: calc(-100% - 13px);
  pointer-events: none;
  z-index: 101;

  //border: 1px solid;
  &::before {
    content: "";
    position: absolute;
    border: 9px solid transparent;
    border-top: 9px solid var(--main-color);
    left: 50%;
    bottom: -18px;
    transform: translateX(-50%);
  }
}

.tooltip-btn {
  .tooltiptext {
    visibility: visible;
  }
}

.tooltiptext-bottom {
  top: 100% !important;
  transform: translate(0, 50%) !important;

  &::before {
    content: "";
    position: absolute;
    border: 9px solid transparent;
    border-bottom: 9px solid var(--blueColor);
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tile-info-propterty {
  input {
    width: 100%;
  }
}

.tile-info-row .tile-info-col {
  border-right: unset !important;
}

.tile-info-row .tile-info-col:last-child {
  flex-wrap: wrap;
  max-width: 285px;
  border-left: 1px solid #ccc;
  justify-content: flex-start;
  min-height: 40px;
  height: unset;
  padding: 8px;

  p {
    color: var(--stoppedColor);
    margin-top: 5px;
  }
}