.nav-bar {
  background: var(--lightDarkColor);
  width: 60px;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 4px 8px rgba(25, 26, 37, 0.8));
  position: relative;
  z-index: 1;

  &__main {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--top {
      overflow-x: hidden;
      overflow-y: auto;
      padding: 30px 10px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    &--bottom {
      padding: 0 10px 30px;
    }

    &--notification {
      position: relative;

      &__value {
        pointer-events: none;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 32px;
        top: 15px;
        color: white;
        line-height: 1;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        font-size: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--blueColor);
        filter: drop-shadow(0px 0px 4px var(--blueColor));
      }
    }
  }

  &__footer {
    height: 90px;
    background: var(--darkColor);

    button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      i.size-1 {
        width: 26px;
        height: 26px;
        min-width: 4px;
      }
    }
  }
}