.app-select {
  width: 100%;
  position: relative;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    width: 100%;

    &.error {
      margin-bottom: 15px;

      .app-select__control {
        border-color: var(--errColor)!important;
      }
    }

    .app-select__label {
      text-transform: uppercase;
      color: var(--blueColor);
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 3px;
      margin-left: 8px;
    }
  }

  &__indicator {
    display: none!important;

    &-separator {
      display: none!important;
    }
  }

  &__indicators {
    position: relative;
    width: 12px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center!important;
    padding: 0;
    margin-right: 10px;

    svg {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 15px;
      position: absolute;
      background: url('../../../../../assets/icons/arrow-down.svg') no-repeat center/contain;
    }
  }

  &__control {
    display: flex!important;
    align-items: center!important;
    min-height: auto!important;
    border: 1px solid transparent!important;
    width: 100%!important;
    background: var(--darkColor)!important;
    border-radius: 15px!important;
    padding-right: 5px;
    box-shadow: none!important;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer!important;
  }

  &__value-container {
    padding: 15px!important;
    width: 100%;
  }

  &__placeholder {
    color: var(--blueColor)!important;
  }

  &__single-value {
    color: var(--blueColor)!important;
  }

  &__input {
    color: black!important;

    &-container {
      border: none!important;
      font-size: 0.8rem;
      width: 100%;
      min-height: auto!important;
      padding: 0!important;
    }
  }

  &__menu {
    min-width: 150px;
    background-color: var(--darkColor)!important;
    border: 1px solid var(--blueColor);
    border-radius: 15px!important;
    overflow: hidden;
  }

  &__menu-list {
    padding: 0!important;
  }

  &__option {
    color: white!important;
    background-color: transparent;
    cursor: pointer!important;
    padding: 15px!important;

    &:hover:not(.app-select__option--is-selected) {
      background-color: transparent;
      color: var(--blueColor)!important;
      text-decoration: underline;
    }

    &--is-focused:not(.app-select__option--is-selected) {
      background-color: transparent!important;
      color: var(--blueColor)!important;
    }

    &--is-selected {
      background-color: var(--blueColor)!important;
      color: white!important;
    }
  }

  &__error {
    position: absolute;
    left: 3px;
    bottom: -15px;
    font-weight: 500;
    color: var(--errColor);
    user-select: none;
    pointer-events: none;
    font-size: .857rem;
  }
}
