.map-tools {
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 12px;
  gap: 10px;

  position: absolute;
  right: 58px;
  top: 55px;


  /* Blue 50 */

  background: var(--lightDarkColor);
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.2);
  border-radius: 16px;

  &__btn {
    i {
      width: 22px !important;
      height: 25px !important;
    }
    &.unit {
      color: var(--blueColor);
      text-transform: uppercase;
      font-size: 18px;
      position: relative;

      &:hover {
        opacity: 1;
      }
    }

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    /* Main/Blue */

    border: 1px solid var(--borderColor);
    border-radius: 12px;
    background: var(--lightDarkColor);
    ;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    &:hover {
      opacity: 0.7;
    }

    &.active {
      background: var(--blueColor);
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.marker-box {
  border-radius: 50%;
  border: 4px solid var(--blueColor);
  background-color: #fff;
}