.tile-info {
  &-wrapper {
    cursor: pointer;
    padding: 10px;
    background-color: #fff;
    pointer-events: auto;
  }

  &-table {
    display: flex;
    flex-direction: column;
    z-index: 99;

    font-size: 16px;
  }

  &-row {
    min-height: 40px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

    &:first-child {
      border-top: 1px solid #ccc;
    }

    .tile-info-col {
      flex: 1;
      height: 40px;
      min-width: 150px;

      &:first-child {
        font-weight: 500;
        flex: 0.5;
        min-width: 187px;
        border-right: 1px solid #ccc;
        padding: 0 5px;
        color: var(--darkColor);
      }

      &:last-child {
        padding: 0 8px;
      }

      color: #4e535c;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;

    button {
      &:hover {
        opacity: 0.8;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }

      &.delete {
        border: none;
        background-color: var(--stoppedColor);
        color: #fff,
      }

      &.submit {
        background-color: var(--blueColor);
        color: #fff;
        border: none;
      }
      min-width: 70px;
      border: 1px solid #ccc;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #79808e;
      padding: 10px;
      background-color: #f7f8fc;
    }
  }
}

.tile-info-propterty {
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    margin-left: 5px;
  }

  input {
    height: 33px;
    padding: 5px 8px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border: 1px solid rgb(226, 229, 235);
  }

  .tile-info-action {
    cursor: pointer;
  }

  span {
    text-transform: capitalize;
  }
}
.leaflet-popup-close-button {
  display: block !important;
}