.time-slider {
  width: 100%;
  height: 13px;
  max-width: 300px;
  margin: 0 7px;

  .track {
    top: 0;
    bottom: 0;
    margin: auto;
    height: 5px;
    background: #575862;
    border-radius: 2.5px;
  }

  .thumb {
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: var(--blueColor);
    box-shadow: 0 0 5px 1px rgba(0, 206, 255, 1);
    cursor: pointer;
    &:focus {
      outline: 0;
    }
  }
}