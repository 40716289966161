.app-btn {
  border: none;
  display: block;
  background: none;
  transition: all .25s ease;

  &:disabled {
    opacity: 0.5;
  }

  &.nav-btn {
    width: 40px;
    height: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--borderColor);
    border-radius: 12px;
    padding: 8px;

    [class^="icon-"],
    [class*=" icon-"] {
      background-color: white;
      width: 100%;
      min-width: auto;
      height: 25px;
    }

    &:hover,
    &.active {
      background: var(--blueColor);
      border-color: var(--blueColor);
      color: var(--navBarBackground);
    }
  }

  &.circle-btn {
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--blueColor);
    background: var(--blueColor);
    border-radius: 50%;
    padding: 4px;

    [class^="icon-"],
    [class*=" icon-"] {
      background-color: var(--navBarBackground);
      width: 70%;
      height: 70%;
      min-width: auto;
    }

    &:hover,
    &.active {
      background: var(--navBarBackground);

      [class^="icon-"],
      [class*=" icon-"] {
        background-color: var(--blueColor);
      }
    }
  }

  &.clean-circle-btn {
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--blueColor);
    background: var(--navBarBackground);
    border-radius: 50%;
    padding: 4px;

    [class^="icon-"],
    [class*=" icon-"] {
      background-color: var(--blueColor);
      width: 70%;
      height: 70%;
      min-width: auto;
    }

    &:hover,
    &.active {
      background: var(--blueColor);

      [class^="icon-"],
      [class*=" icon-"] {
        background-color: var(--navBarBackground);
      }
    }
  }

  &.arrow-btn {
    width: 20px;
    height: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--borderColor);
    border-radius: 4px;
    background: var(--navBarBackground);
    padding: 4px;

    [class^="icon-"],
    [class*=" icon-"] {
      background-color: var(--blueColor);
      width: 13px;
      height: 13px;
      min-width: 13px;
    }

    &.active {
      [class^="icon-"],
      [class*=" icon-"] {
        transform: scaleY(-1);
      }
    }
  }

  &.main-btn {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    border: 1px solid var(--blueColor);
    background: var(--blueColor);
    transition: all .25s ease;
    padding: 6px 15px;
    color: var(--darkColor);
    box-shadow: 0 0 15px var(--blueColor);
    border-radius: 15px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;

    span {
      font-size: 12px;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      background-color: var(--darkColor);
      width: 18px;
      height: 18px;
      min-width: 18px;
    }

    &:hover,
    &.active {
      background: var(--navBarBackground);
      color: var(--blueColor);

      [class^="icon-"],
      [class*=" icon-"] {
        background-color: var(--blueColor);
      }
    }
  }

  &.clean-main-btn {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    border: 1px solid var(--blueColor);
    background: var(--navBarBackground);
    transition: all .25s ease;
    padding: 6px 15px;
    color: var(--blueColor);
    box-shadow: 0 0 15px var(--blueColor);
    border-radius: 15px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;

    span {
      font-size: 12px;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      background-color: var(--blueColor);
      width: 18px;
      height: 18px;
      min-width: 18px;
    }

    &:hover,
    &.active {
      background: var(--blueColor);
      color: var(--navBarBackground);

      [class^="icon-"],
      [class*=" icon-"] {
        background-color: var(--navBarBackground);
      }
    }
  }
}