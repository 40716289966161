.main-modal-wrapper {
  position: relative;
  padding: 30px 15px;
  height: 100%;
  max-width: 400px;
  background: var(--lightDarkColor);
  pointer-events: auto;
  display: flex;
  flex-direction: column;

  &.satelliteInfoModal {
    z-index: 1;
    right: 40px !important;
    max-width: 340px;
  }

  &.small {
    min-width: 340px;
    position: fixed;
    height: auto;
    border-radius: 30px;
    top: 100px;
    right: 22px;
    padding: 20px 30px;

    .main-modal-wrapper__title {
      font-size: 30px;
      text-transform: capitalize;
    }

    .main-modal-wrapper__close {
      width: 32px;
      height: 32px;
      right: -2px;
      top: -11px;
    }
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--blueColor);
    box-shadow: 0px 0px 5px var(--blueColor);
    border-radius: 50%;
    z-index: 1;
  }
  .button {
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--blueColor);
    box-shadow: 0px 0px 5px var(--blueColor);
    border-radius: 50%;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
  }

  &__content {
    flex: 1;
    height: 100%;
  }
}