.comparison-model {
  &-wrapper {
    width: 476px;
    border-radius: 12px 12px 12px 25px;
    position: absolute;
    z-index: 401;
    bottom: 0;
    left: 0;
    filter: drop-shadow(0px 4px 8px rgba(25, 26, 37, 0.8));
  }

  &__title {
    border-radius: 12px 12px 0 0;
    background: #191A25;
    padding: 18px 0 15px 17px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;

    p {

      margin-left: 7px;
      color: var(--textColor);
    }
  }

  &__body {
    padding: 0 15px;
    background-color: var(--lightDarkColor);
    border-radius: 0 0 12px 25px;
  }

  &__project-name {
    border-bottom: 1px solid #373846;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    padding: 25px 0 15px 0;
    color: var(--textColor);
  }

  &__choose-mode {
    &-wrapper {
      margin-top: 10px;
      width: 50%;
    }
  }

  &__choose-version {
    padding: 15px 0 25px 0;
    &-title {
      color: var(--blueColor);
      font-weight: 500;
      font-size: 10px;
      line-height: 11px;
      text-transform: uppercase;
      font-style: normal;
    }

    &-wrapper {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .select-version-wrapper {
        width: 45%;
      }
    }

    &-icon {
      height: 100%;

      i {
        margin-top: 20px;
      }
    }
  }
}