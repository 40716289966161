.sr-only {
  display: none;
}

.map {
  position: relative;
  flex: 1;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  background-color: var(--darkColor);
  transition: all .25s ease;
  opacity: 1;

  .hidden {
    opacity: 0;
  }

  .gMapWrapper {
    height: calc(100% + 25px);
    background-color: var(--darkColor);
  }
}

.leaflet-map {
  position: relative;
  flex: 1;
  height: 100% !important;
  border-radius: 30px;
  overflow: hidden;
  background-color: var(--darkColor);
  transition: all .25s ease;
  opacity: 1;

  &.hidden {
    opacity: 0;
  }

  // .tile-info-table {
  //   display: table;
  //   border-collapse: collapse;
  //   tr {
  //     display: table-row;
  //     border: 1px solid #ddd;
  //     &:nth-child(even) {
  //       background-color: #F9F9F9;
  //     }
  //     td {
  //       display: table-cell;
  //       padding: 8px;
  //       border-left: 1px solid #ddd;
  //       &:first-child {
  //         min-width: 185px;
  //         border-left:0;
  //         font-weight: bold;
  //       }
  //       &:last-child {
  //         min-width: 420px;
  //       }
  //     }
  //   }
  // }
}

.leaflet-popup-content-wrapper {
  width: fit-content;
  min-width: 658px;
}

.leaflet-popup-content {
  width: calc(100% - 45px) !important;
}

.leaflet-div-icon {
  border-radius: 50%;
  border: 4px solid var(--blueColor);
}

.leaflet-control {
  display: none;
}