.confirmation-form {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  background-color: var(--lightDarkColor);
  border-radius: 8px;

  &-title {
    border-radius: 8px 8px 0 0;
    background-color: var(--darkColor);
    padding: 10px 20px 10px 20px;

    p {
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      text-transform: uppercase;
    }
  }

  &-body {
    background-color: var(--lightDarkColor);
    padding: 10px 20px 10px 20px;
    border-radius: 0 0 8px 8px;

    p {
      font-size: 14px;
      color: #fff;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 10px 20px;

    .btn {
      width: 45%;
      span {
        display: flex;
        align-items: center;
        i {
          margin-right: 8px;
        }
      }
      &:hover {
        i {
          background-color: var(--blueColor);
        }

        background-color: #fff;
      }
      margin-top: 0;
    }

    .btn-delete {
      background-color: #B00020;
    }
  }
}