.compare-map {
  position: relative;
  flex: 1;
  height: 100% !important;
  border-radius: 30px;
  overflow: hidden;
  background-color: var(--darkColor);
  transition: all .25s ease;
  opacity: 1;

  &.hidden {
    opacity: 0;
  }
}

.leaflet-popup-content {
  width: calc(100% - 45px) !important;
}

.version-label {
  position: fixed;
  z-index: 400;
  background-color: var(--darkColor);
  opacity: 0.9;
  border-radius: 4px;
  color: var(--blueColor);
  padding: 6px 10px;
  font-size: 16px;
  font-weight: 600;
  bottom: 104px;

  &.left {
    left: 119px;
  }

  &.right {
    right: 60px;
  }
}