.textField {
    &-wrapper {

    }

    &-label {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
        margin-left: 8px;
        margin-bottom: 3px;
        color: var(--blueColor);
    }

    &-input {
        input {
            border-radius: 8px;
            padding: 15px;
            background-color: #191A25;
            font-size: 14px;
            height: 43px;
            width: 100%;
            color: var(--blueColor);
            font-weight: 500;
        }
    }

    &-error {
        
    }
}