.input-text {
  position: relative;
  width: 100%;

  p {
    text-transform: uppercase;
    color: var(--blueColor);
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 3px;
    margin-left: 8px;
  }

  &.error {
    margin-bottom: 15px;

    input {
      border-color: var(--errColor)!important;
    }
    input::placeholder {
      color: var(--errColor);
    }
  }

  input {
    display: block;
    height: 42px;
    width: 100%;
    background-color: var(--darkColor);
    border: 1px solid transparent;
    border-radius: 15px;
    font-weight: 300;
    color: var(--blueColor);
    font-size: 14px;
    line-height: 1;
    padding: 15px;
    transition: all .25s ease;
    box-shadow: none;

    &:focus {
      border-color: var(--blueColor);
    }

    &::placeholder {
      color: var(--fontBlueColor);
      font-size: 14px;
      line-height: 1;
    }
  }

  &__error {
    position: absolute;
    left: 3px;
    bottom: -15px;
    font-weight: 500;
    color: var(--errColor);
    user-select: none;
    pointer-events: none;
    font-size: .857rem;
  }
}
.satellite-input{
  margin-bottom: 3px;
  input {
    height: 30px;
    padding-right: 25px;
    
  }
  p{
    text-transform: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    position: absolute;
    left: 0;
    top:50%;
    transform: translateY(-50%);
    margin-left: 15px;
    pointer-events: none;
  }
  
}
.satellite-input.input-text.error{
  margin-bottom: 3px;
}

.satellite-input__icon {
  top: 50%;
  position: absolute;
  z-index: 1;
  right: 10px;
  transform: translateY(-50%);
  cursor: default;
  
}

.tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 5px 15px;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform: translate(-50%, calc(-100% - 5px));
}


.satellite-input__icon:hover .tooltiptext {
  visibility: visible;
}