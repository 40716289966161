.custom-icon {
  color: #fff;
  font-size: 22px;
  display: flex;
  align-items: end;
  justify-content: flex-end;
}

.marker-label {
  font-weight: 600;
  font-size: 14px;
  color: #ff3333;
  background-color: #fff;
}