[class^="icon-"],
[class*=" icon-"] {
  width: 16px;
  height: 16px;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #9BA1AD;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;

  &.color-primary {
    background-color: var(--blueColor);
  }
  &.color-black {
    background-color: var(--darkColor);
  }
  &.color-red {
    background-color: var(--errColor);
  }
  &.color-white {
    background-color: white;
  }
  &.color-blue {
    background-color: var(--blueColor);
  }
  &.color-grey {
    background-color: var(--textGreycolor);
  }

  &.size-1 {
    height: 14px;
    width: 14px;
    min-width: 14px;
  }
  &.size-2 {
    height: 16px;
    width: 16px;
    min-width: 16px;
  }
  &.size-3 {
    height: 18px;
    width: 18px;
    min-width: 18px;
  }
  &.size-4 {
    height: 44px;
    width: 44px;
    min-width: 44px;
  }
  &.size-5 {
    height: 30px;
    width: 30px;
    min-width: 30px;
    margin-left: 5px;
  }
  &.size-6 {
    height: 30px;
    width: 30px;
    min-width: 30px;
  }
}

// Icon list
.icon {

  &-search {
    mask-image: url("../../../../assets/icons/search.svg");
  }
  &-exit {
    mask-image: url("../../../../assets/icons/exit.svg");
  }
  &-filter {
    mask-image: url("../../../../assets/icons/filter.svg");
  }
  &-close {
    mask-image: url("../../../../assets/icons/close.svg");
  }
  &-list {
    mask-image: url("../../../../assets/icons/list.svg");
  }
  &-plus {
    mask-image: url("../../../../assets/icons/plus.svg");
  }
  &-plus-small {
    mask-image: url("../../../../assets/icons/plus-small.svg");
  }
  &-notification {
    mask-image: url("../../../../assets/icons/notification.svg");
  }
  &-add-satellite {
    mask-image: url("../../../../assets/icons/add-satellite.svg");
  }
  &-satellite {
    mask-image: url("../../../../assets/icons/satellite.svg");
  }
  &-telescope {
    mask-image: url("../../../../assets/icons/telescope.svg");
  }
  &-doc {
    mask-image: url("../../../../assets/icons/doc.svg");
  }
  &-upload {
    mask-image: url("../../../../assets/icons/upload.svg");
  }
  &-download {
    mask-image: url("../../../../assets/icons/downlload.svg");
  }
  &-trash {
    mask-image: url("../../../../assets/icons/trash.svg");
  }
  &-calendar {
    mask-image: url("../../../../assets/icons/calendar.svg");
  }
  &-user {
    mask-image: url("../../../../assets/icons/user.svg");
  }
  &-version {
    mask-image: url("../../../../assets/icons/version.svg");
  }
  &-arrow-up {
    mask-image: url("../../../../assets/icons/arrow_up.svg");
  }
  &-arrow-down {
    mask-image: url("../../../../assets/icons/arrow-down.svg");
  }
  &-arrow-right {
    mask-image: url("../../../../assets/icons/arrow-right-icon.svg");
  }
  &-arrow-left {}
  &-arrow-sub-right {}
  &-polygon {
    mask-image: url("../../../../assets/icons/polygon.svg");
  }
  &-box {
    mask-image: url("../../../../assets/icons/box.svg");
  }
  &-accept {
    mask-image: url("../../../../assets/icons/accept.svg");
  }
  &-cancel {
    mask-image: url("../../../../assets/icons/cancel.svg");
  }
  &-polygons {}
  &-move {
    mask-image: url("../../../../assets/icons/move.svg");
  }
  &-info {}
  &-compare {}
  &-more {
    mask-image: url("../../../../assets/icons/more.svg");
  }
  &-play {
    mask-image: url("../../../../assets/icons/play.svg");
  }
  &-pause {
    mask-image: url("../../../../assets/icons/pause.svg");
  }
  &-refresh {
    mask-image: url("../../../../assets/icons/refresh.svg");
  }
  &-retrain {
    mask-image: url("../../../../assets/icons/retrain.svg");
  }
  &-measure {
    mask-image: url("../../../../assets/icons/measure.svg");
  }
  &-eye {}
  &-eye-closed {
    mask-image: url("../../../../assets/icons/eye-closed.svg");
  }
  &-checked {}
  &-pin {
    mask-image: url("../../../../assets/icons/pin.svg");
  }
  &-map {
    mask-image: url("../../../../assets/icons/map.svg");
  }
  &-link {}
  &-question-mark {}
  &-request {
    mask-image: url("../../../../assets/icons/request.svg");
  }
  &-question{
    mask-image: url("../../../../assets/icons/question.svg");
  }
  &-location{
    mask-image: url("../../../../assets/icons/location.svg");
  }
  &-check{
    mask-image: url("../../../../assets/icons/check.svg");
  }
  &-uncheck{
    mask-image: url("../../../../assets/icons/uncheck.svg");
  }
  &-down{
    mask-image: url("../../../../assets/icons/down.svg");
  }
  &-slider{
    mask-image: url("../../../../assets/icons/slider.svg");
  }
  &-ic-slider{
    mask-image: url("../../../../assets/icons/ic-slider.svg");
  }
  &-switch{
    mask-image: url("../../../../assets/icons/switch.svg");
  }
  &-bounding {
    mask-image: url("../../../../assets/icons/bounding.svg");
  }
  &-pin-icon{
    mask-image: url("../../../../assets/images/pin-icon.svg");
  }
  &-sync {
    mask-image: url("../../../../assets/icons/sync.svg");
  }
  &-link{
    mask-image: url("../../../../assets/icons/link.svg");
  }
  &-left-arrow{
    mask-image: url("../../../../assets/icons/left-arrow.svg");
  }
  &-right-arrow{
    mask-image: url("../../../../assets/icons/right-arrow.svg");
  }
  &-play-icon{
    mask-image: url("../../../../assets/icons/play-icon.svg");
  }
  &-pause-icon{
    mask-image: url("../../../../assets/icons/pause-icon.svg");
  }
  &-edit {
    mask-image: url("../../../../assets/icons/edit.svg");
  }
  &-save {
    mask-image: url("../../../../assets/icons/save.svg");
  }
  &-ruler {
    mask-image: url("../../../../assets/icons/ruler.svg");
  }
  &-area {
    mask-image: url("../../../../assets/icons/area.svg");
  }
  &-tif-folder {
    mask-image: url("../../../../assets/icons/tif-folder.svg");
  }
  &-select-area {
    mask-image: url("../../../../assets/icons/select-area.svg");
  }
  &-grid {
    mask-image: url("../../../../assets/icons/grid.svg");
  }
  &-cursor-selection {
    mask-image: url("../../../../assets/icons/cursor-selection.svg");
  }
  &-mechanic-repair {
    mask-image: url("../../../../assets/icons/mechanic-repair.svg");
  }
}