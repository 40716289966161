@import "src/assets/styles/variavles";

.earthWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;

  .peSceneContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #222222;
    z-index: var(--earthZIndex);
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    canvas {
      &:focus {
        outline: 0;
      }
    }
  }

  .backgroundScene {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    visibility: visible;
    background: url('../../../assets/images/bg.jpg') no-repeat center center fixed;
    background-size: cover;
    transition: opacity 1s ease-in, visibility 0.1s ease 1s;
    z-index: var(--earthBGZIndex);
    &.done {
      opacity: 0;
      visibility: hidden;
    }
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .topModal {
    top: 100px !important;
    height: inherit !important;
    padding: 20px 17px 12px !important;
  }

  .timeController {
    position: fixed;
    right: 40px;
    top: 540px;
    z-index: 20;
    background: var(--lightDarkColor);
    border-radius: var(--defaultBorderRadius);
    height: 130px;
    width: 340px;
    padding: 20px 17px 0;

    .header {
      text-align: center;

      .headerName {
        text-decoration: underline;
      }

      .headerSpeed, .headerDate, .headerName {
        font-weight: 600;
        font-size: 1.3rem;
        color: var(--blueColor);
        margin-bottom: 5px;
      }

      .headerSpeed {
        font-size: 1.5rem;
      }
    }

    .timeSlider {
      .timeSliderHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .timeSliderHeaderVal {
          color: var(--blueColor);
          font-size: .857rem;
          margin-bottom: 5px;

          span {
            font-size: .9em;
            color: inherit;
          }
        }
      }

      .timeSliderWrap {
        display: flex;
        align-items: center;
        justify-content: center;

        .changeSpeedBtn {
          cursor: pointer;
        }

        .timeSliderBtn {
          width: 12px;
          height: 12px;
          color: var(--blueColor);
          //mask: url("src/assets/images/right-arrow_icon.svg") no-repeat center;
          mask-size: cover;
          &:first-of-type {
            transform: rotate(180deg);
            will-change: transform;
          }
        }
      }
    }

    .btn {
      position: absolute;
      bottom: -35px;
      left: 0;
      right: 0;
      margin: auto;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: var(--navBarBackground);

      .icon {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 54px;
        height: 54px;
        border: 2px solid var(--blueColor);
        border-radius: 50%;
        background-size: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.pause {
          //background-image: url("src/assets/images/pause_icon.svg");
          background-position-x: 55%;
        }
      }
    }

    .resetBtn {
      position: absolute;
      left: 34px;
      bottom: 5px;
      background-color: transparent;
      box-shadow: none;
      color: var(--blueColor);
      font-weight: 500;
      &.satellite {
        bottom: 5px;
      }
    }
  }
}


