.scroll-wrapper {
  background: var(--darkColor);
  border-radius: 20px;
  padding: 15px 30px 15px 20px;

  &.no-bg {
    background: transparent;
    padding: 0;

    .ScrollbarsCustom-Wrapper .ScrollbarsCustom-Scroller {
      padding: 0!important;
    }

    .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
      margin-right: -5px;
    }
  }

  .ScrollbarsCustom-Wrapper .ScrollbarsCustom-Scroller {
    padding: 15px 30px 15px 20px;
    padding-right: 40px!important;
  }

  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    margin-right: 10px;
    width: 5px!important;
    background: var(--navBarBackground)!important;
    overflow: unset!important;
  }

  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    width: 9px!important;
    background: var(--blueColor)!important;
    border-radius: 15px!important;
    position: relative;
    left: -2px;
  }
}