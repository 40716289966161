.map-style {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  z-index: 999;
  align-items: center;
  background-color: #22222e;
  border-radius: 25px;

  .map-style-button {
    width: 40px;
    height: 40px;
    background-color: #2A2B37;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    margin-right: 8px;
    border: 2px solid transparent;
    transition: 0.4s;
    background-position: center;
    background-size: 55%;
    background-repeat: no-repeat;

    &:last-child {
      margin-right: 0;
    }
  }

  .map-style-button-toggle {
    width: 50px;
    height: 50px;
    background-color: var(--darkColor);
    background-size: 60%;
    background-repeat: no-repeat;
  }

  .style-active {
    border: 2px solid var(--blueColor);
    box-shadow: 0px 0px 6px var(--blueColor);
  }

  .satellite {
    background-image: url(../../../../../assets/map-styles/satellite-icon.svg);
  }

  .hybrid {
    background-image: url(../../../../../assets/map-styles/hybrid-icon.svg);
  }

  .roadmap {
    background-image: url(../../../../../assets/map-styles/roadmap-icon.svg);
  }
}