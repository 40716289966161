.confirmation-modal {
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 100;
  }

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  color: var(--blueColor);
  border-radius: 16px;
  // padding: 10px 20px;
  background-color: var(--lightDarkColor);
  min-width: 350px;
  max-width: 350px;

  &-header {
    text-transform: uppercase;
    padding: 0 16px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
  }

  &-body {
    padding: 0 16px;
    color: var(--blueColor);
    font-size: 14px;
    font-weight: 600;

    &.warning {
      color: #B00020;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px;
    color: black;

    &-btn {
      background: var(--blueColor);
      border: 1px solid var(--blueColor);
      box-shadow: 0px 0px 15px var(--blueColor);
      border-radius: 15px;
      display: flex;
      padding: 10px 15px;
      text-transform: uppercase;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      text-transform: uppercase;
      margin: auto;
      margin-top: 15px;
      i{
          margin-top: -3px;
          margin-right: 5px;
      }

      &.cancel {
        color: var(--blueColor);
        background: var(--lightDarkColor)
      }
    }
  }
}