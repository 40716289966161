.multiple-control {
  background-color: var(--lightDarkColor);
  padding: 10px;
  border-radius: 8px;
  animation: fadeIn ease 1s;
  position: fixed;
  bottom: 115px;
  left: 38%;
  z-index: 999;
  min-width: 525px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 15px rgba(18, 165, 252, .5);
  justify-content: space-between;

  &-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;

    .app-btn:not(:first-child) {
      margin-left: 100px;
    }

    .app-btn {
      margin-top: 0;
      box-shadow: 0 0 5px 1px rgba(0, 206, 255, 1);
    }
  }

  .btn-delete {
    background-color: #B00020;
    box-shadow: unset;
    border: none;
    color: #fff;
  }

  .btn-clear {
    background-color: #515262;
    box-shadow: unset;
    border: none;
    color: #fff;
  }
}

@keyframes fadeIn {
  from { opacity: 0 }
  to   { bottom: 1 }
}