.unit-select {
  &-list {
    position: absolute;
    top: 0;
    right: calc(100% + 20px);
    background: var(--lightDarkColor);
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 10px 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  &-item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--borderColor);

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}