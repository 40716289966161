@import "variavles";
@import "fonts";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: var(--fontSize);
  color: var(--color);
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: var(--fontSize);
  font-family: var(--fontFamily);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:hover, a:focus {
  font-family: var(--fontFamily);
  text-decoration: none;
  color: inherit;
}
input, button, textarea {
  font-size: var(--fontSize);
  font-family: var(--fontFamily);
  box-shadow: none;
  border: 0;
  outline: 0;
  &:focus {
    outline: 0;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
}

button {
  cursor: pointer;
  box-shadow: none;
  border: 0;
}

ul, ol {
  list-style: none;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.g-map {
  > div {
    > div {
      &:last-of-type {
        pointer-events: none;

        > div > div {
          pointer-events: auto;
        }
      }
    }
  }
}

.Toastify {
  * {
    color: var(--blueColor)
  }

  .Toastify__toast {
    border-radius: 33px;
  }
}
