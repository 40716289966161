.marker-icon {
  z-index: 1;
  position: absolute;
  //background-color: #F0F2F5;
  background-color: var(--bg-color-dark);
  //box-shadow: 0 12px 24px rgba(58, 63, 72, 0.11), inset -2px -1px 4px rgba(188, 193, 203, 0.39), inset 4px 4px 8px rgba(255, 255, 255, 0.9);
  box-shadow: var(--shadow-2);
  border-radius: 2px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--title-color);
}