.leaflet-sbs-range {
    -webkit-appearance: none;
    display: inline-block;
    vertical-align: middle;
    height: 0;
    padding: 0;
    margin: 0;
    border: 0;
    background: rgba(0, 0, 0, 0.25);
    min-width: 100px;
    cursor: pointer;
    pointer-events: none;
    z-index: 999;
}
.leaflet-sbs-range.hidden {
  display: none;
}
.leaflet-sbs-range::-ms-fill-upper {
    background: transparent;
}
.leaflet-sbs-range::-ms-fill-lower {
    background: rgba(255, 255, 255, 0.25);
}
/* Browser thingies */

.leaflet-sbs-range::-moz-range-track {
    opacity: 0;
}
.leaflet-sbs-range::-ms-track {
    opacity: 0;
}
.leaflet-sbs-range::-ms-tooltip {
    display: none;
}
/* For whatever reason, these need to be defined
 * on their own so dont group them */

.leaflet-sbs-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    background: #fff;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: ew-resize;
    pointer-events: auto;
    border: 1px solid #ddd;
    background-image: url(range-icon.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 40px 40px;
}
.leaflet-sbs-range::-ms-thumb {
    margin: 0;
    padding: 0;
    background: #fff;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: ew-resize;
    pointer-events: auto;
    border: 1px solid #ddd;
    background-image: url(range-icon.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 40px 40px;
}
.leaflet-sbs-range::-moz-range-thumb {
    padding: 0;
    right: 0    ;
    background: #fff;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: ew-resize;
    pointer-events: auto;
    border: 1px solid #ddd;
    background-image: url(range-icon.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 40px 40px;
}
.leaflet-sbs-range:disabled::-moz-range-thumb {
    cursor: default;
}
.leaflet-sbs-range:disabled::-ms-thumb {
    cursor: default;
}
.leaflet-sbs-range:disabled::-webkit-slider-thumb {
    cursor: default;
}
.leaflet-sbs-range:disabled {
    cursor: default;
}
.leaflet-sbs-range:focus {
    outline: none!important;
}
.leaflet-sbs-range::-moz-focus-outer {
    border: 0;
}

