.projectMarkerIcon {
  display: block;
  width: 24px;
  height: 34px;
  background-image: url("../../../../../../assets/images/pin-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}

.leaflet-marker-icon:has(.projectMarkerIcon) {
  width: 0 !important;
  height: 0 !important;
  border: none;
}
.projectMarker {
    position: relative;
    width: 24px;
    height: 68px;
    .tooltip {
      position: absolute;
      background-color: white;
      top: 0;
      left: -55px;
      right: 0;
      width: 130px;
      transform: translateY(-115%);
      text-align: center;
      font-size: .9rem;
      border: 1px solid var(--lightDarkColor);
      padding: 5px;
      border-radius: 2pz;
      user-select: none;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.4s;
      will-change: opacity;
      overflow: hidden;
      word-break: break-all;
      &.active {
        opacity: .7;
      }
    }
  }
.leaflet-popup {
    margin-left: 6px !important;
}
.leaflet-popup-content-wrapper{
  min-width: 60px !important;
  white-space: nowrap;
  .leaflet-popup-content{
    width: calc(100% - 20px) !important;
    margin: 0 10px !important;
    text-align: center;

  }
}
.leaflet-popup-content p{
  margin: 10px 0 !important;
}