.drop-down {
  &-select {
    position: relative;
  }

  &-value {
    text-transform: capitalize;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
  }

  &-list {
    z-index: 99;
    background-color: #fff;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    border-radius: 8px;

    &__value {
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #ccc;
      }

      &:first-child {
        border-radius: 8px 8px 0 0;
      }

      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }
  }
}