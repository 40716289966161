.main-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__header {
    background: var(--darkColor);
    height: 70px;
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 0 45px;

    img {
      width: 200px;
    }
  }

  &__content {
    flex: 1;
    height: 100%;
    display: flex;

    &--wrapper {
      position: relative;
      padding: 25px;
      background: #2A2B37;
      flex: 1;
      width: 100%;
      height: 100%;
    }

    &--view {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      overflow: hidden;
    }

    &--modals {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      pointer-events: none;
    }
  }
}

#project-object-portal {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 400;
  pointer-events: none;
}