:root {
  --fontSize: 14px;
  --textColor: #ffffff;
  --fontFamily: 'Gotham Pro';
  --blueColor: #00CEFF;
  --lightDarkColor: #22222E;
  --darkColor: #191A25;
  --errColor: red;
  --navBarBackground: #2A2B37;
  --borderColor: #373846;
  --headerHeight: 50px;
  --textGreycolor: #515262;

  // status colors
  --stoppedColor: #DA1E1E;
  --runningColor: #FFC121;
  --lastVersionColor: #00A3FF;
  --completedColor: #44DCB7;

  --defaultBorderRadius: 30px;

  --earthZIndex: 1;
  --earthBGZIndex: 2;
  --headerZIndex: 20;
  --loginFormZIndex: 3;
  --mapTypeControllerZIndex: 3;
  --navBarZIndex: 3;
  --satelliteInfoZIndex: 3;
  --mapZIndex: 3;
  --galleryZIndex: 21;

  // notification
  --toastify-toast-min-height: 50;
  --toastify-color-dark: var(--darkColor);
}