.vehicle-info {
  &-wrapper {
    pointer-events: auto;
    border-radius: 10px;
    width: 476px;
    background-color: var(--lightDarkColor);
    color: #ffffff;
    box-shadow: 0px 4px 8px 0px #191a25cc;
  }

  &-header {
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    padding: 15px;
    background-color: var(--darkColor);
    text-transform: uppercase;
    border-radius: 10px 10px 0 0;
  }

  &-body {
    padding: 25px 15px 25px 15px;
  }

  &-footer {
    border-top: 1px solid #373846;
    padding: 15px;
    display: flex;
    justify-content: space-between;

    .app-btn {
        &:not(:last-child) {
            margin-right: 10px;
        }

        &.btn-delete {
          background-color: #B00020;
          box-shadow: unset;
          border: none;
          color: #fff;
        }
    }

    .btn-group {
      display: flex;
      align-content: center;
    }
  }

  &-field {
   &:not(:first-child) {
    margin-top: 20px;
   } 
  }
}
