.nav-bar-switch {
  display: block;
  background: #1E1F2A;
  border-radius: 30px;
  padding: 4px;

  span {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: center;

    b {
      width: 40px;
      height: 40px;
      background: transparent;
      box-shadow: none;
      border-radius: 30px;
      transition: all .25s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.active {
        background: var(--blueColor);
        box-shadow: 0px 0px 6px var(--blueColor);

        i.size-1 {
          background-color: #1E1F2A;
        }
      }

      i.size-1 {
        width: 24px;
        height: 24px;

        background-color: var(--blueColor);
      }
    }
  }
}